import * as React from "react"
import Question from "../question"

import "./styles.scss"

const questionGroup = ({ blok, topicId, maximumCount=0 }) => {
    return (
        <div className="questionGroup">
            <h3>{blok.title}</h3>
            <div className="questions">
                {blok.questions.map((question, i) =>
                    <div key={i}>
                        <Question blok={question} topicId={topicId} alternativeLabel="teilweise" maximumCount={maximumCount} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default questionGroup