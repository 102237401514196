import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import QuestionGroup from "../components/questionGroup"
import Question from "../components/question"
import Button from "../components/button"
import Seo from "../components/seo"

import "../styles/topic-pages.scss"

const TopicPage1 = (context) => {
  const pageContent = JSON.parse(context.pageContext.story.content)

  let maximumCount = 0
  pageContent.statusQuo.forEach(group => {
    maximumCount += group.questions.length
  })
  maximumCount += pageContent.whatDoWeNeed.length

  return (
    <Layout>
      <Seo title={context.pageContext.story.name} />
      <Link to="/" className="backButton">
        <div className="icon"></div>
        <div className="label">Zurück zur Übersicht</div>
      </Link>
      <div className="topic-page">
        <h1>{context.pageContext.story.name}</h1>
        <div className="full-page">
        <h2>Wo stehen wir und wie gehen wir aktuell mit dem Thema um?</h2>
        {pageContent.statusQuo.length === 1 &&
        <div className="status-quo only-questions">
          {pageContent.statusQuo[0].questions.map((question, i) =>
            <Question key={i} blok={question} topicId={pageContent.topicLabel} alternativeLabel="teilweise" maximumCount={maximumCount} />
          )}
        </div>
        }
        {pageContent.statusQuo.length > 1 &&
        <div className="status-quo">
          {pageContent.statusQuo.map((question, i) =>
            <QuestionGroup key={i} blok={question} topicId={pageContent.topicLabel} maximumCount={maximumCount} />
          )}
        </div>
        }
        <div className="participants">
          <h2>Welche Akteure brauche ich?</h2>
          <ul>
          {pageContent.participants.map((participant, i) =>
            <li key={i}>{participant.title}</li>
          )}
          </ul>
        </div>
        <div className="actions">
          <Button type="secondary" to={"/"} label="Zurück" />
          <Button type="primary" to={`/${context.pageContext.story.full_slug}/bedarf`} label="Weiter" />
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default TopicPage1
